import React from 'react';

function Icon({ icon = 'Warning', size = 24, color, onClick, customStyle = {}, customClass = '' }) {
  const src = `/icons/${icon}Icon${color ? `_${color}` : ''}.png`;

  return (
    <img
      src={src}
      alt={`${icon} Icon`}
      className={`Padded ${customClass}`}
      style={{
        width: `${size}px`,
        verticalAlign: 'middle',
        cursor: onClick ? 'pointer' : 'default',
        ...customStyle,
      }}
      onClick={onClick}
    />
  );
}

export default Icon;
