// Import Libraries
import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faTrashCan } from '@fortawesome/free-solid-svg-icons';

// Import Components
import Icon from '../Icon';

function ImageUpload({ uploadAttachment, image, deleteAttachment }) {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    uploadAttachment(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    uploadAttachment(file);
  };

  return (
    <span className="OnePortionFlex Padded" style={{ alignSelf: 'center', textAlign: 'center' }}>
      <input
        type="file"
        id="fileInput"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <span
        className={`${isDragging ? 'dragging' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        htmlFor="fileInput"
        onClick={() => fileInputRef.current.click()}
      >
        {image ? (
          <img src={image} alt="Shot Image" className={`loading-box shotImage`} />
        ) : (
          <FontAwesomeIcon icon={faCloudArrowUp} style={{ fontSize: '42px' }} />
        )}
      </span>

      {image ? (
        <Icon
          icon="Trash"
          color="Orange"
          size="22"
          customClass="closeTrashIcon"
          customStyle={{ margin: '0px 0px 0px -29px' }}
          onClick={() => deleteAttachment()}
        />
      ) : null}
    </span>
  );
}

export default ImageUpload;
