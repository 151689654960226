// Import Libraries
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// Import Components
import Select from '../../components/Elements/Select';
import Sorter from '../components/sortOrder';
import SearchElement from '../components/searchElement';
import UserInfo from '../components/user/userbox';
import CreditUsageList from '../components/user/UserCredits';
import Subscription from '../../components/UserComponents/subscription';

// Import Modals
import AddCreditsModal from '../components/subscriptions/addCreditsModal';

// Import Services
import AdminSubSvc from '../services/adminSubscriptionService';
import AdminUserSvc from '../services/adminUserService';
import AdminUtils from '../services/utils';

function AdminSubscriptionPage() {
  const [subs, setSubs] = useState([]);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [openUser, setopenUser] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [isCreditModalOpen, setIsCreditModalOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState({});
  const [plans, setPlans] = useState([]);
  const [criteria, setCriteria] = useState({
    limit: 25,
    sort: { field: 'id', direction: 'DESC' },
    search: {},
    offset: 0,
  });

  function updateCriteria(type, value) {
    let newCriteria = { ...criteria };
    newCriteria[type] = value;
    setCriteria(newCriteria);
  }

  const getSubscriptionList = async () => {
    let subList = await AdminSubSvc.getAllSubscriptions(criteria);
    setSubs(subList.subs);
    setTotalRows(subList.totalCount);
  };

  const updateSort = (field) => {
    let newCriteria = { ...criteria };
    if (field === criteria.sort.field && criteria.sort.direction === 'DESC') {
      newCriteria['sort'] = { field: field, direction: 'ASC' };
    } else {
      newCriteria['sort'] = { field: field, direction: 'DESC' };
    }
    setCriteria(newCriteria);
  };

  const closeCreditModal = (updatedSub) => {
    setSubs((prevSubs) => {
      return prevSubs.map((sub) => {
        if (sub.id === updatedSub.id) {
          return {
            ...sub,
            available_credits: updatedSub.available_credits,
            end_date: updatedSub.end_date,
          };
        }
        return sub;
      });
    });
    setIsCreditModalOpen(false);
  };

  const onSearch = (field, value, type) => {
    let newCriteria = { ...criteria };
    if (!newCriteria.search[field]) {
      newCriteria.search[field] = { value: '', type: 'LIKE' };
    }
    if (value) {
      newCriteria.search[field].value = value;
    } else if (value !== null) {
      if (
        newCriteria.search[field].type.includes('>') ||
        newCriteria.search[field].type.includes('<')
      ) {
        newCriteria.search[field].value = 0;
      } else {
        newCriteria.search[field].value = '';
      }
    }
    if (type) {
      newCriteria.search[field].type = type;
      if (type === 'Any') {
        delete newCriteria.search[field];
      } else if (!value && (type.includes('>') || type.includes('<'))) {
        newCriteria.search[field].value = 0;
      }
    }
    setCriteria(newCriteria);
  };

  function createPageArray(pageCount) {
    const result = [];
    for (let i = 1; i <= pageCount; i++) {
      result.push(i);
    }
    return result;
  }

  const getUser = async (sub) => {
    let criteria = {
      limit: 1,
      sort: { field: 'id', direction: 'DESC' },
      search: { id: { value: sub.user_id, type: '=' } },
      offset: 0,
    };
    let getUser = await AdminUserSvc.getAllUsers(criteria);
    return getUser.users[0];
  };

  const viewSubDetail = async (sub) => {
    if (sub) {
      let userDetails = await getUser(sub);
      setopenUser(userDetails);
    } else {
      setopenUser({});
    }
  };

  const updateCredits = async (sub) => {
    let userDetails = await getUser(sub);
    setModalDetails({
      sub: sub,
      user: userDetails,
    });
    setIsCreditModalOpen(true);
  };

  useEffect(() => {
    getSubscriptionList();
  }, [criteria]);

  /*
  useEffect(() => {
    const getPlans = async () => {
      let planList = await AdminSubSvc.getAllPlans();
      let planOptions = Object.values(planList).map((plan) => ({
        label: plan.name + ' - ' + plan.price + ' - ' + plan.frequency,
        value: plan.id,
      }));
      planOptions.unshift({ label: 'Any', value: '' });
      console.log(planOptions);
      setPlans(planOptions);
    };
    getPlans();
  }, []);
*/

  return (
    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
      {modalDetails.user && modalDetails.sub && isCreditModalOpen ? (
        <AddCreditsModal
          user={modalDetails.user}
          sub={modalDetails.sub}
          isModalOpen={isCreditModalOpen}
          setModalIsOpen={closeCreditModal}
        />
      ) : null}
      <div
        className="FullWidthFlex"
        style={{ paddingTop: '15px', paddingBottom: '15px', marginRight: '30px' }}
      >
        <span className="FourPortionFlex large">Admin Subscription Management</span>

        <span className="OnePortionFlex bold">{`Found ${totalRows} Subscriptions`}</span>
        <span className="OnePortionFlex"></span>

        <span className="TwoPortionFlex FullWidthFlex">
          <Select
            selectLabel={`Page`}
            labelStyle={`static`}
            selectOptions={createPageArray(Math.ceil(totalRows / criteria.limit))}
            selectedValue={Math.floor(criteria.offset / criteria.limit) + 1}
            handleSelectChange={(value) => updateCriteria('offset', (value - 1) * criteria.limit)}
            className="TwoPortionFlex"
          />
          <span
            className="OnePortionFlex"
            style={{ paddingLeft: '10px', paddingTop: '15px' }}
          >{` of ${Math.ceil(totalRows / criteria.limit)}`}</span>
        </span>
        <span className="TwoPortionFlex">
          <Select
            selectLabel={`Rows per page`}
            labelStyle={`static`}
            selectOptions={[10, 25, 50, 100, 300]}
            selectedValue={criteria.limit}
            handleSelectChange={(value) => updateCriteria('limit', value)}
          />
        </span>
        <span className="OnePortionFlex"></span>
      </div>

      {subs && (
        <div className="FullWidthContainer staff">
          <div
            className={`card flatLeftCard TenPortionFlex`}
            style={{
              marginRight: '30px',
            }}
          >
            <div className="cardHeader fullWidthFlex bold" style={{ cursor: 'pointer' }}>
              <span className="OnePortionFlex" onClick={() => updateSort('id')}>
                ID
                <Sorter sort={criteria.sort} field={`id`} />
              </span>
              <span className="OnePortionFlex" onClick={() => updateSort(`user_id`)}>
                User ID <Sorter sort={criteria.sort} field={`user_id`} />
              </span>
              {/*<span className="OnePortionFlex" onClick={() => updateSort(`plan_id`)}>
                Plan <Sorter sort={criteria.sort} field={`plan`} />
          </span>*/}
              <span className="OnePortionFlex" onClick={() => updateSort(`frequency`)}>
                Renews <Sorter sort={criteria.sort} field={`frequency`} />
              </span>
              <span className="OnePortionFlex" onClick={() => updateSort(`price`)}>
                Price <Sorter sort={criteria.sort} field={`price`} />
              </span>
              <span className="ThreePortionFlex" onClick={() => updateSort(`start_date`)}>
                Started <Sorter sort={criteria.sort} field={`start_date`} />
              </span>
              <span className="ThreePortionFlex" onClick={() => updateSort(`end_date`)}>
                Ended <Sorter sort={criteria.sort} field={`end_date`} />
              </span>
              {/*
              <span className="OnePortionFlex" onClick={() => updateSort(`available_credits`)}>
                Available Credits <Sorter sort={criteria.sort} field={`available_credits`} />
              </span>
        */}
              <span className="OnePortionFlex purpleText large">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  onClick={() => setDisplaySearch(!displaySearch)}
                />
              </span>
            </div>
            {displaySearch && (
              <div className="FullWidthFlex" style={{ paddingBottom: '10px' }}>
                <span className="OnePortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=', '>', '<', 'LIKE']}
                    fieldName={`id`}
                    search={criteria.search['id']}
                    onSearch={onSearch}
                  />
                </span>
                <span className="OnePortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=', '>', '<', 'LIKE']}
                    fieldName={`user_id`}
                    search={criteria.search['user_id']}
                    onSearch={onSearch}
                  />
                </span>
                {/*}
                <span className="OnePortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=']}
                    fieldName={`plan`}
                    search={criteria.search['plan']}
                    onSearch={onSearch}
                    inputType="select"
                    values={plans}
                  />
                </span>
            */}
                <span className="OnePortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=', 'LIKE']}
                    fieldName={`frequency`}
                    search={criteria.search['frequency']}
                    onSearch={onSearch}
                  />
                </span>
                <span className="OnePortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=', '>', '<', 'LIKE']}
                    fieldName={`price`}
                    search={criteria.search['price']}
                    onSearch={onSearch}
                  />
                </span>
                <span className="ThreePortionFlex fullWidthContainer top"></span>
                <span className="ThreePortionFlex fullWidthContainer top"></span>
                {/*
                <span className="OnePortionFlex fullWidthContainer top">
                  <SearchElement
                    searchOptions={['Any', '=', '>', '<', 'LIKE']}
                    fieldName={`available_credits`}
                    search={criteria.search['available_credits']}
                    onSearch={onSearch}
                  />
                </span>
          */}
                <span className="OnePortionFlex fullWidthContainer top"></span>
              </div>
            )}
          </div>

          <div className="hiddenScroll" style={{ height: 'calc(100vh - 285px)' }}>
            {subs.map((sub) => (
              <div
                key={sub.id}
                className={`card flatLeftCard TenPortionFlex ${
                  openUser && sub && openUser.id === sub.user_id ? 'selectedCard' : null
                }`}
              >
                <div className="cardHeader">
                  <span className="OnePortionFlex">{sub.id}</span>
                  <span className="OnePortionFlex">{sub.user_id}</span>
                  {/*<span className="OnePortionFlex">{sub.plan_id}</span>*/}
                  <span className="OnePortionFlex">{sub.frequency}</span>
                  <span className="OnePortionFlex">
                    {sub.price
                      ? sub.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                      : ''}
                  </span>
                  <span className="ThreePortionFlex">
                    {sub && sub.start_date ? AdminUtils.FormatDateWithTime(sub.start_date) : ''}
                  </span>
                  <span className="ThreePortionFlex">
                    {sub && sub.end_date ? AdminUtils.FormatDateWithTime(sub.end_date) : ''}
                  </span>
                  {/*
                  <span className="OnePortionFlex linkText" onClick={() => updateCredits(sub)}>
                    {sub.available_credits || 0}
                  </span>
                    */}
                  <span className="OnePortionFlex">
                    {openUser && sub && openUser.id !== sub.user_id ? (
                      <FontAwesomeIcon
                        className="linkText"
                        icon={faEye}
                        onClick={() => viewSubDetail(sub)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="linkText orangeText"
                        icon={faEyeSlash}
                        onClick={() => viewSubDetail(null)}
                      />
                    )}
                  </span>
                </div>

                {openUser && sub && openUser.id === sub.user_id ? (
                  <>
                    <div className="fullWidthContainer" style={{ width: '96%' }}>
                      <div className="fullWidthContainer">
                        <div className="FivePortionFlex" style={{ padding: '2%' }}>
                          <UserInfo activeUser={openUser} />
                        </div>

                        <div className="FivePortionFlex" style={{ padding: '2%' }}>
                          <Subscription userDetails={openUser} />
                        </div>
                      </div>

                      <div className="fullWidthContainer" style={{ padding: '2%' }}>
                        <CreditUsageList
                          openUser={openUser}
                          subscription={sub}
                          level={'subscription'}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminSubscriptionPage;
