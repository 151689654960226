// Libraries
import React, { useState } from 'react';

// Import Component
import UpgradeMembership from '../../components/Modals/upgradeMembership.js';

// Construct Component
const UserCreditBar = ({ level, availableCredits }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function closeMembershipModal(goToFreeTrial) {
    setModalIsOpen(false);
  }

  return (
    <>
      <UpgradeMembership
        isOpen={modalIsOpen}
        onRequestClose={closeMembershipModal}
        featureMsg={'Upgrade to pro for more generated scenes and premium features.'}
        scriptId={null}
      />
      <div
        className="fullWidthContainer"
        style={{
          background: 'white',
          marginTop: '20px',
          marginBottom: '20px',
          borderLeft: '3px solid #7A64EB',
          color: '#7A64EB',
        }}
      >
        <span className="sizeUp" style={{ padding: '15px' }}>
          You can generate a shotlist for
          {availableCredits ? (
            <>
              {availableCredits.proFreemium ? (
                <span className="Padded">
                  10 scenes of your first project
                  {availableCredits.basicFreemium || availableCredits.totalCredits ? ', ' : null}
                </span>
              ) : null}
              {availableCredits.basicFreemium ? (
                <span className="Padded">
                  5 scenes of your second project
                  {availableCredits.totalCredits ? ' and ' : null}
                </span>
              ) : null}
              {level !== 'pro' ? (
                <span className="Padded">
                  {!availableCredits.basicFreemium && !availableCredits.proFreemium ? (
                    <>0 scenes of your next project. </>
                  ) : null}
                  <a onClick={() => setModalIsOpen(true)} className="linkText">
                    {' '}
                    Upgrade to Pro for more options.
                  </a>
                </span>
              ) : availableCredits.totalCredits >= 100 ? (
                <>
                  {availableCredits.basicFreemium || availableCredits.proFreemium ? ' and ' : null}
                  <span className="Padded">
                    {' '}
                    {availableCredits.totalCredits / 100} more scripts this month
                  </span>
                </>
              ) : !availableCredits.basicFreemium && !availableCredits.proFreemium ? (
                <span className="Padded">0 scenes of your next project </span>
              ) : null}
            </>
          ) : (
            <span className="Padded">0 scenes of your next project.</span>
          )}
        </span>
      </div>
    </>
  );
};

export default UserCreditBar;
