// Libraries
import React, { useState, useEffect, useContext, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { FullStory, init } from '@fullstory/browser';
import { pdfjs } from 'react-pdf';

// Styles
import './styles/App.css';
import './styles/modalStyles.css';
import './styles/formStyles.css';
import './styles/flexStyling.css';
import './styles/header.css';
import './styles/pageStructure.css';
import './styles/cards.css';

// Components
import HeaderBar from './components/HeaderBar';
import FooterBar from './components/FooterBar';
import LogUserIn from './components/UserComponents/logUserIn';
import IdleModal from './components/Modals/IdleModal';
import ModalMimicPage from './components/PageStructure/modalMimicPage.js';
import GeneralModal from './components/Modals/GeneralModal.js';
//import SampleScript from '/Sample_Script.pdf';

// Pages
import ViewScriptDetail from './pages/sceneAndShotlistView/scriptView.js';
import UserDashboard from './pages/userDashboard/userDashboard.js';
import DownloadSample from './pages/downloadSample';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsOfUse from './pages/termsOfUse';
import ExternalPage from './pages/externalPage';
import Profile from './pages/profile';
// Welcome User Pages
import WelcomeUserMain from './pages/welcomeUser/newUserWelcome.js';
import WelcomeUserFooter from './pages/welcomeUser/newUserWelcomeFooter.js';
import FreeTrialActivated from './pages/welcomeUser/freeTrialActivated.js';
// Script Pages
import SecureScriptFooter from './pages/createProject/secureScriptFooter.js';
import SelectScriptType from './pages/createProject/selectScriptType.js';
import UploadScriptBox from './pages/createProject/uploadScript.js';
import CreateManualProject from './pages/createProject/createManualProject.js';

// Admin Pages
import AdminUser from './admin/pages/user';
import AdminSubs from './admin/pages/subscriptions';

// Utilities
import userService from './services/userService';
import paymentService from './services/paymentService';
import subscriptionService from './services/subscriptionService';
import { valid } from 'semver';

// Setup Application Contexts
import SessionContext, { SessionProvider } from './context/Session';
export const UserContext = React.createContext({});

// Setup Font Awesome
library.add(faTimes);
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  // Setup Context Data
  const [user, setUser] = useState({});

  // Setup Auth Data and Functionality
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();

  // Setup State Data
  const [scriptData, setScriptData] = useState(null);
  const [isStaff, setIsStaff] = useState(false);
  const [isIdleOpen, setIsIdleOpen] = useState(false);
  const uploadedFile = scriptData ? scriptData.file : null;

  // Setup Temp Data
  let lastActiveTime = new Date();

  async function fetchToken() {
    try {
      await checkActive();
      let validLocalToken = false;
      if (localStorage.getItem('token')) {
        validLocalToken = await userService.validateToken();
        if (!validLocalToken) {
          validLocalToken = await refreshToken();
        }
      } else {
        validLocalToken = await refreshToken();
      }
      if (!validLocalToken) {
        closeUserSession();
      }
      return validLocalToken;
    } catch (error) {
      closeUserSession();
    }
  }

  async function checkIfStaff() {
    try {
      let validLocalStaffToken = await userService.validateStaffToken();
      setIsStaff(validLocalStaffToken);
      if (window.location.pathname.substring(0, 6) == '/admin' && !validLocalStaffToken) {
        window.location.pathname = '/app/home';
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function refreshToken() {
    return new Promise(async (resolve, reject) => {
      try {
        setTimeout(async function () {
          try {
            const accessToken = await getAccessTokenSilently({ grant: 'refresh_token' });
            let validLocalToken = await userService.validateToken(accessToken);
            resolve(validLocalToken);
          } catch (error) {
            resolve(false);
          }
        }, 1000);
      } catch (error) {
        resolve(false);
      }
    });
  }

  function closeUserSession() {
    let path = window.location.pathname;
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      logout({ logoutParams: { returnTo: window.location.origin } });
    } else if (path.substring(0, 6) == '/admin' || path.substring(0, 4) == '/app') {
      window.location.pathname = '/';
    }
  }

  useEffect(() => {
    async function setupTimeout() {
      await handleUserInteraction();
      let authorized = await fetchToken();
      if (authorized && !user.id) {
        let userData = await userService.getUserData();
        userData.cards = await paymentService.getAvailableCards(userData.id);
        userData.invoiceDue = await paymentService.checkForPaymentsDue(userData.id);
        setUser(userData);
      }
      checkIfStaff();
      setInterval(fetchToken, 150000);
    }

    /* REMOVE AFTER GOING LIVE WITH SHOTKRAFT.COM
    if (window.location.pathname === '/' && window.location.hostname === 'www.shotkraft.app') {
      // Redirect the user temporarily to shotkraft
      window.location.href = 'https://www.shotkraft.com';
    }
      */

    setupTimeout();

    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('keydown', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('keydown', handleUserInteraction);
    };
  }, []);

  async function checkActive() {
    const currentDate = new Date();
    const timeDifference = currentDate - lastActiveTime;
    const inactivityThreshold = 30 * 60 * 1000;

    if (localStorage.getItem('token')) {
      if (!isIdleOpen && timeDifference >= inactivityThreshold - 120000) {
        setIsIdleOpen(true);
      }
      if (timeDifference >= inactivityThreshold) {
        closeUserSession();
      }
    }
  }

  async function handleUserInteraction() {
    const currentTime = new Date();
    lastActiveTime = currentTime;
  }

  useEffect(() => {
    if (user && user.id) {
      window.usetifulTags = { userId: user.id };
      init({ orgId: 'o-1STRV8-na1' });
      FullStory('setIdentity', { uid: user.id, properties: user });
    }
  }, [user]);

  const RerouteSample = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate('/DownloadSample');
    }, []);

    return <div></div>;
  };

  return (
    <>
      <SessionProvider>
        <Router>
          <UserContext.Provider value={{ user, setUser }}>
            {!isLoading ? (
              <>
                <IdleModal isOpen={isIdleOpen} onRequestClose={setIsIdleOpen} />
                <HeaderBar isStaff={isStaff} />
                <div className="pageHolder">
                  <Routes>
                    {/* ========= GENERIC PAGES =========== */}
                    <Route path="/DownloadSample" element={<DownloadSample />} />
                    <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
                    <Route exact path="/termsOfUse" element={<TermsOfUse />} />

                    {/* ========= AUTH PAGES =========== */}
                    <Route path="/login" element={<LogUserIn />} />

                    {/* ========= NEW USER FLOWS =========== */}
                    <Route
                      exact
                      path="/app/newUserWelcome"
                      element={
                        <ModalMimicPage
                          page={<WelcomeUserMain userDetails={user} />}
                          footer={<WelcomeUserFooter />}
                          close={`/app/home`}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/app/freeTrialActivated"
                      element={
                        <ModalMimicPage
                          page={<FreeTrialActivated userDetails={user} />}
                          footer={<SecureScriptFooter />}
                        />
                      }
                    />

                    {/* ========= CREATE SCRIPT FLOWS =========== */}
                    <Route
                      exact
                      path="/app/newProject/1"
                      element={
                        <ModalMimicPage
                          page={<SelectScriptType />}
                          footer={<SecureScriptFooter />}
                          close={`/app/home`}
                          width="800"
                        />
                      }
                    />
                    <Route
                      exact
                      path="/app/newProject/2"
                      element={
                        <ModalMimicPage
                          page={<UploadScriptBox />}
                          footer={<SecureScriptFooter />}
                          close={`/app/home`}
                          back={true}
                          width="800"
                        />
                      }
                    />
                    <Route
                      exact
                      path="/app/newManualProject/2"
                      element={
                        <ModalMimicPage
                          page={<CreateManualProject />}
                          footer={<SecureScriptFooter />}
                          close={`/app/home`}
                          back={true}
                          width="800"
                        />
                      }
                    />

                    {/* ========= STANDARD FLOWS =========== */}
                    <Route
                      exact
                      path="/"
                      element={<ExternalPage page="https://www.shotkraft.com" />}
                    />
                    <Route exact path="/app/home" element={<UserDashboard />} />
                    <Route exact path="/app/profile" element={<Profile user={user} />} />
                    <Route
                      exact
                      path="/app/shotList/:scriptId"
                      element={<ViewScriptDetail uploadedFile={uploadedFile} />}
                    />

                    {/* ========= ADMIN PAGES =========== */}
                    <Route path="/so/f0OoXTviG/*" element={<RerouteSample />} />
                    {isStaff ? (
                      <>
                        <Route path="/admin/users" element={<AdminUser />} />
                        <Route path="/admin/subscriptions" element={<AdminSubs />} />
                        <Route
                          path="/admin/scripts/shotList/:scriptId"
                          element={<ViewScriptDetail uploadedFile={uploadedFile} />}
                        />
                      </>
                    ) : null}

                    {/* ========= OTHER PAGES =========== */}
                    <Route path="/health" element={<h3>ShotList is Healthy!</h3>} />
                  </Routes>
                </div>
                <FooterBar />
              </>
            ) : null}
          </UserContext.Provider>
        </Router>
        <GeneralModal />
      </SessionProvider>
    </>
  );
}

export default App;
