// Import Libraries
import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

// Import Context
import ScriptContext from '../../../context/Script.js';
import SessionContext from '../../../context/Session';

// Import Services
import ScriptService from '../../../services/scriptService';

//Import Components
import Icon from '../../Elements/Icon';
import ProFlag from '../../../components/Elements/ProFlag.js';

// Construct Component
const SceneGenerateSection = ({ scene, view, setView }) => {
  const { script } = useContext(ScriptContext);
  const { isMobile } = useContext(SessionContext);
  const [floatDirection, setFloatDirection] = useState(isMobile ? 'left' : 'right');

  const generateShots = async (sceneNumber) => {
    const foundSceneIndex = script.scenes.findIndex((original) => original.id === sceneNumber);

    if (foundSceneIndex !== -1) {
      try {
        await ScriptService.getShotListForScene(script.id, script.scenes[foundSceneIndex].id);
      } catch (error) {
        console.error('Error generating shots:', error);
      }

      setView((prevView) => ({ ...prevView, updateDictionary: true }));
    }
  };

  function changePdfView() {
    setView({ ...view, pdfActive: !view.pdfActive });
  }

  useEffect(() => {
    setFloatDirection(isMobile ? 'left' : 'right');
  }, [isMobile]);
  return (
    <span
      className={`${isMobile ? 'FullWidthFlex' : 'TenPortionFlex'}`}
      style={{
        marginTop: isMobile ? '10px' : '0px',
      }}
    >
      {script && scene?.ai_status <= 1 ? (
        <>
          {scene.isGeneratingShots === true || scene.ai_status === 1 ? (
            <img
              src="/Spinner-2.9s-297px.gif"
              className="loadingIcon icon"
              style={{ float: floatDirection, height: '40px', width: '40px' }}
              alt="Loading.."
            />
          ) : scene.scene_text && script.features && script.features.toLowerCase() !== 'basic' ? (
            <button
              className={`buttonClone`}
              style={{ marginLeft: isMobile ? '0px' : '7px', float: floatDirection }}
              onClick={() => generateShots(scene.id)}
            >
              Generate
            </button>
          ) : view.uploadedFile && (!scene.scene_text || scene.scene_text === '') ? (
            <span className="orangeText" style={{ float: floatDirection, paddingRight: '20px' }}>
              No Scene Text
            </span>
          ) : (
            <>
              <button
                className={`buttonClone`}
                style={{
                  float: floatDirection,
                  marginLeft: '0px',
                  marginRight: '0px',
                }}
                disabled="disabled"
              >
                Generate
              </button>
              <ProFlag
                type="pro"
                featureMsg="Click here to upgrade, or continue to shotlist manually."
                scriptLevel={script.features}
                scriptId={script.id}
              />
            </>
          )}
        </>
      ) : view.uploadedFile &&
        script.scenes[view.sceneIndex]?.id == scene.id &&
        (view.viewColumns == 2 || isMobile) ? (
        <button
          className={`buttonClone ${!isMobile ? 'TwoPortionFlex' : ''}`}
          onClick={() => changePdfView()}
          style={{ float: floatDirection, padding: '5px' }}
        >
          <Icon icon="Pdf" color="White" size={16} />
          <FontAwesomeIcon
            icon={view.pdfActive ? faCaretUp : faCaretDown}
            className={`sizeUp SmallIcon`}
            style={{ color: '#ffffff', marginTop: '5px', paddingRight: '5px' }}
          />
        </button>
      ) : script.scenes[view.sceneIndex]?.id !== scene.id && scene.shot_list ? (
        <span className="greenText" style={{ float: floatDirection, paddingRight: '20px' }}>
          Ready
        </span>
      ) : null}
    </span>
  );
};

export default SceneGenerateSection;
