// Import Libraries
import React, { useContext, useState, useEffect } from 'react';

// Import Component
import Stepper from '../../components/Elements/Stepper.js';
import UpgradeMembership from '../../components/Modals/upgradeMembership.js';
import FreeTrial from '../../components/Modals/freeTrial.js';
import LoadingSpinner from '../../components/Elements/LoadingSpinner';

// Services
import userService from '../../services/userService';
import scriptService from '../../services/scriptService';

// Import User Context
import { UserContext } from '../../App';

// Construct Component
function CreateManualProject() {
  const { user, setUser } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [scriptName, setScriptName] = useState('');
  const [credits, setCredits] = useState(100);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [trialModalIsOpen, setTrialModalIsOpen] = useState(false);

  function closeMembershipModal(goToFreeTrial) {
    setModalIsOpen(false);
    if (goToFreeTrial) {
      setTrialModalIsOpen(true);
    }
  }

  function closeTrialModal() {
    setCredits(300);
    setTrialModalIsOpen(false);
  }

  const handleScriptNameChange = async (event) => {
    let scriptName = event.target.value;
    setScriptName(scriptName);
  };

  const createScript = async () => {
    try {
      setProcessing(true);
      setError(null);
      let userData = user;
      if (!userData) {
        console.error('No user data found. Fetching...');
        userData = await userService.getUserData();
        setUser(userData);
      }
      if (!scriptName) {
        setError('A valid script name is required');
      } else if (!userData.id) {
        setError('Invalid User. Log back in and try again.');
      } else {
        let createScript = await scriptService.createManualScript(userData.id, scriptName);
        setTimeout(() => {
          setError('O-oh! Something went wrong. Check your internet, reload, and try again. ');
          return;
        }, 30000);
        if (createScript) {
          const newUrl = `/app/shotList/${createScript}`;
          window.location.href = newUrl;
        } else {
          setError('Error creating manual script. Please try again.');
        }
      }
      setProcessing(false);
    } catch (error) {
      console.error('Error creating script:', error);
      setError('O-oh! Something went wrong. Check your internet, reload, and try again. ');
      setProcessing(false);
    }
  };

  useEffect(() => {
    async function getUserCredits() {
      let credits = await userService.getUserAvailableCredits();
      setCredits(credits);
    }
    getUserCredits();
  }, [user, modalIsOpen]);

  return (
    <>
      <UpgradeMembership
        isOpen={modalIsOpen}
        onRequestClose={closeMembershipModal}
        featureMsg="Continue shotlisting fast with Pro features"
      />
      <FreeTrial isOpen={trialModalIsOpen} onRequestClose={closeTrialModal} />

      <div className="fullWidthContainer" style={{ textAlign: 'left' }}>
        <div className="fullWidthContainer large bold">Let's get started!</div>

        <div className="fullWidthContainer">
          <p>
            Enter your script scene by scene, or in one go for short visual treatments. Start by
            entering a script title.
          </p>
        </div>
        <div className="fullWidthContainer">
          <Stepper numSteps={3} onStep="2" urlOverride={`/app/newProject`} />
        </div>
        <div className="fullWidthContainer">
          <span className="OnePortionFlex">Script Title </span>
        </div>
        <div className="fullWidthContainer">
          <span className="ThreePortionFlex">
            <input
              type="text"
              value={scriptName}
              onChange={handleScriptNameChange}
              placeholder="i.e. Gold egg 30 sec spot"
              className="Input"
            />
          </span>
        </div>
        <div className="fullWidthContainer">
          {user.level && user.level.toLowerCase() === 'basicplus' ? (
            <div>
              <p className="errorText">
                You are running out of pro credits. You may not be able to complete shotlisting this
                script with the benefit of pro features.
                <span className="linkText" onClick={() => setModalIsOpen(true)}>
                  Upgrade now.
                </span>{' '}
              </p>
              <br />
            </div>
          ) : (!credits || credits < 100) && user.level && user.level.toLowerCase() === 'pro' ? (
            <div>
              <p className="errorText">
                You are out of pro credits for this month. You will not be able to complete
                shotlisting this script with the benefit of pro features.
                <a className="linkText" href="https://www.shotkraft.com/help" target="_blank">
                  Contact Us to get more credits.
                </a>{' '}
              </p>
              <br />
            </div>
          ) : null}
          <div className="fullWidthContainer">
            <p className="errorText">{error}</p>
          </div>
          <div className="fullWidthContainer">
            <span className="OnePortionFlex"></span>
            <button
              className="ThreePortionFlex buttonClone MinSmall"
              onClick={() => createScript()}
              disabled={processing}
            >
              {processing ? <LoadingSpinner /> : 'Next'}
            </button>

            <span className="OnePortionFlex"></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateManualProject;
