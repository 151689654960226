// Import Libraries
import React from 'react';

// Import Component
import Stepper from '../../components/Elements/Stepper.js';
import Icon from '../../components/Elements/Icon.js';

// Construct Component
function SelectScriptType() {
  const uploadPDF = () => {
    window.location.href = `/app/newProject/2`;
  };

  const createManualScript = () => {
    window.location.href = `/app/newManualProject/2`;
  };

  return (
    <div className="fullWidthContainer" style={{ textAlign: 'left' }}>
      <div className="fullWidthContainer large bold">Ready to shotlist?</div>
      <div className="fullWidthContainer">
        <p className="subHeader">
          We can generate a shotlist from a narrative script or from your visiual treatment in any
          language. How will you enter your material?{' '}
        </p>
        <Stepper numSteps={3} onStep="1" />
        <div className="fullWidthContainer" style={{ paddingTop: '10px' }}>
          <div
            className="SevenPortionFlex MinSmall"
            style={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: '20px' }}
          >
            <div
              className="DeepPadded lightPurpleBackground"
              style={{
                border: '1px dashed #7A64EB',
                borderRadius: '16px',
              }}
            >
              <button className="buttonClone fullWidth" onClick={() => uploadPDF()}>
                <Icon
                  icon="Upload"
                  color="White"
                  customStyle={{ width: '20px', paddingRight: '10px' }}
                />
                Upload Script PDF
              </button>
              <ul>
                <li className="Padded">
                  Standard screenplay format
                  <br />
                  <a target="_blank" href="/DownloadSample" className="purpleText">
                    View Sample
                  </a>
                </li>
                <li className="Padded">Scenes begin with a slug line (i.e. INT/EXT ...)</li>
                <li className="Padded">File is 5 MB or less</li>
              </ul>
            </div>
          </div>
          <div
            className="FivePortionFlex"
            style={{ paddingLeft: '20px', paddingRight: '20px', marginBottom: '20px' }}
          >
            <div
              className="DeepPadded"
              style={{
                backgroundColor: '#FDFDFD',
                border: '1px dashed #AAAAAA',
                borderRadius: '16px',
              }}
            >
              <button
                className="buttonCloneDark fullWidth"
                style={{ paddingLeft: '0px', paddingRight: '0px' }}
                onClick={() => createManualScript()}
              >
                <Icon
                  icon="CopyAndPaste"
                  color="White"
                  customStyle={{ width: '20px', paddingRight: '10px' }}
                />
                Copy/Paste Script
              </button>
              <ul>
                <li className="Padded">Non-standard screenplay format</li>
                <li className="Padded">Commercial or Music video</li>
                <li className="Padded">Visual Treatment</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectScriptType;
