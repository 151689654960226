// Import Libraries
import React, { useState, useEffect, useContext } from 'react';

// Import Services
import subscriptionService from '../../../services/subscriptionService';

// Context
import { UserContext } from '../../../App';

const ConfirmCancelSubscription = ({ currentSubscription, onRequestClose }) => {
  const { user } = useContext(UserContext);
  const [message, setMessage] = useState(null);

  const onConfirm = async () => {
    setMessage(false);
    let cancelSubscription = await subscriptionService.cancelSubscription(
      currentSubscription.id,
      user.id
    );

    if (cancelSubscription) {
      onRequestClose(true);
    } else {
      setMessage(true);
    }
  };

  return (
    <>
      <div className="modalHeader bold">
        You are about to cancel your subscription. Are you sure?
      </div>

      <div className="modalContent">
        <div className="FullWidthFlex Padded" style={{ paddingBottom: '30px' }}>
          <span className="OnePortionFlex Padded">
            Your Pro subscription gives you access to exclusive features and content, including:
            <ul>
              <li>The ability to generate custom shotlists for up to 3 scripts per month</li>
              <li>Image Upload and Export Functionality</li>
              <li>Export to CSV that makes it easy to work offline</li>
              <li>More fields and shot list customization, such as multi-cam features</li>
            </ul>
            Your subscription will stay active until the last day of your current enrollment, which
            is {currentSubscription.end_date}.
            <br />
            <br />
            Scripts that already have generated shot lists will remain accessible, however, ability
            to export or edit Images and other Pro features will be disabled.
          </span>
        </div>

        <div className="FullWidthFlex">
          <button className="TwoPortionFlex buttonClone" onClick={() => onRequestClose(true)}>
            Nevermind, keep my subscription
          </button>
          <span className="OnePortionFlex"></span>
          <button className="TwoPortionFlex buttonClone warningButton" onClick={() => onConfirm()}>
            Cancel my Subscription
          </button>
        </div>

        {message && (
          <div id="payment-message fullWidthContainer padded">
            There was an error cancelling your subscription. Please try again later or{' '}
            <a href="https://www.shotkraft.com/contact-us">contact us</a>.
          </div>
        )}
      </div>
    </>
  );
};

export default ConfirmCancelSubscription;
