import userService from './userService';
import utils from './utilities';

const shotService = {
  addShot: async function (sceneId, shotPosition) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/scripts/addShot`;
      const body = JSON.stringify({
        scene: sceneId,
        shotPosition: shotPosition,
      });

      const newEntry = await fetch(url, utils.params('POST', body));

      if (newEntry.ok) {
        const respond = await newEntry.json();
        return respond;
      } else {
        console.error('Error adding dictionary entry');
        return null;
      }
    } catch (err) {
      console.error(err);
    }
  },

  uploadAttachment: async function (scriptPath, shotId, selectedFile) {
    try {
      const formData = new FormData();
      formData.append('scriptPath', scriptPath);
      formData.append('shotId', shotId);
      formData.append('attachment', selectedFile);

      let url = `${process.env.REACT_APP_API_URL}/scripts/uploadShotAttachment`;
      const uploadResponse = await fetch(url, utils.params('POST', formData));
      if (uploadResponse.ok) {
        const blob = await uploadResponse.blob();
        return URL.createObjectURL(blob);
      } else if (uploadResponse.status === 500) {
        return { success: false, error: 'File does not meet our requirements.' };
      } else {
        let processResp = await uploadResponse.json();
        return { success: false, error: processResp.message };
      }
    } catch (error) {
      throw new Error(`Upload failed: ${error.message}`);
    }
  },

  retrieveImg: async function (shotId) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/scripts/shotImages/${shotId}`;
      const image = await fetch(url, utils.params('GET'));

      if (!image.ok) {
        throw new Error('Error retrieving image for shot ' + shotId);
      }

      const blob = await image.blob();
      return URL.createObjectURL(blob);
    } catch (err) {
      console.error(err);
      return null;
    }
  },

  deleteShotImgs: async function (shotId) {
    try {
      const url = `${process.env.REACT_APP_API_URL}/scripts/shotImages/${shotId}`;
      const image = await fetch(url, utils.params('DELETE'));

      if (!image.ok) {
        throw new Error('Error retrieving image for shot ' + shotId);
      }

      return true;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
};

export default shotService;
