// Import Libraries
import React, { useContext } from 'react';

//Import Components
import Icon from '../../Elements/Icon';

// Import Context
import SessionContext from '../../../context/Session';

function ControlBox({ scene, shot, deleteHandler, addShot, expandContractShot, view }) {
  const { isMobile } = useContext(SessionContext);

  return (
    <div
      className={`${
        isMobile ? 'fullWidthContainer optionListHorizontal' : 'OnePortionFlex optionList'
      }`}
    >
      <div
        style={{ padding: '5px', textAlign: 'center' }}
        className={`${view.viewColumns === 1 ? 'OnePortionFlex' : null} linkText`}
        onClick={() => {
          deleteHandler(scene.id, shot.id);
        }}
      >
        <Icon icon="Trash" size={20} color="Orange" />
      </div>
      {view.viewColumns > 1 || isMobile ? (
        <div
          style={{ padding: '5px', textAlign: 'center' }}
          className={`${view.viewColumns === 1 ? 'OnePortionFlex' : null} linkText`}
          onClick={() => {
            expandContractShot(shot);
          }}
        >
          {shot.expanded ? (
            <Icon icon="Contract" size={20} color="Black" />
          ) : (
            <Icon icon="Expand" size={20} color="Black" />
          )}
        </div>
      ) : null}
      <div
        style={{ padding: '5px', textAlign: 'center' }}
        className={`${view.viewColumns === 1 ? 'OnePortionFlex' : null} linkText`}
        onClick={() => {
          addShot(shot.id);
        }}
      >
        <Icon icon="Add" size={20} color="Black" />
      </div>
    </div>
  );
}

export default ControlBox;
