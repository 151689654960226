import React, { useState, useContext } from 'react';

// Import Components
import UpgradeMembership from '../Modals/upgradeMembership.js';
import GeneralModal from '../Modals/GeneralModal.js';
import Icon from './Icon.js';
//import FreeTrial from '../Modals/freeTrial.js';
//import PurchaseSub from '../Fragments/Payments/ProcessSubCharge.js';

// Import Services
import ScriptService from '../../services/scriptService';

// Context
import { UserContext } from '../../App';

function ProFlag({ type, featureMsg, scriptLevel, scriptId, popoverStyle }) {
  const { user } = useContext(UserContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [trialModalIsOpen, setTrialModalIsOpen] = useState(false);
  const [purchaseSubModalIsOpen, setPurchaseSubModalIsOpen] = useState(false);
  const [generalModalDetails, setGeneralModalDetails] = useState({
    open: false,
    header: '',
    message: '',
  });

  function closeMembershipModal(goToFreeTrial) {
    setModalIsOpen(false);
    if (goToFreeTrial) {
      //setTrialModalIsOpen(true);
      setPurchaseSubModalIsOpen(true);
    }
  }

  const upgradeScriptModal = () => {
    setGeneralModalDetails({
      open: true,
      header: <span>Convert Script to Pro?</span>,
      message: (
        <div className="Padded">
          You currently have
          {user && user.availableCredits ? (
            <>
              {user.availableCredits.totalCredits ? (
                <span className="Padded"> {user.availableCredits.totalCredits / 100} </span>
              ) : null}
            </>
          ) : (
            <span className="Padded">0</span>
          )}
          pro scripts available this month. Would you like to convert this script to Pro? This will
          allow you to use advanced features when shotlisting this script, including auto-generating
          a shotlist.
          <br />
          <br />
          <div className="fullWidthContainer Padded">
            <button
              className="buttonGray OneHalfFlex"
              onClick={() =>
                setGeneralModalDetails({
                  open: false,
                  header: null,
                  message: null,
                })
              }
            >
              Cancel
            </button>
            <button
              className="OneHalfFlex buttonClone"
              onClick={() => confirmUpgradeScript(scriptId)}
            >
              Convert Script
            </button>
          </div>
        </div>
      ),
    });
  };

  const confirmUpgradeScript = async (scriptId, level) => {
    let updatedLevel = await ScriptService.changeScriptLevel(scriptId, level, user.id, true);
    window.location.reload();
  };

  return (
    <>
      <UpgradeMembership
        isOpen={modalIsOpen}
        onRequestClose={closeMembershipModal}
        featureMsg={``}
        scriptId={scriptId}
      />

      {generalModalDetails && (
        <GeneralModal
          generalModalDetails={generalModalDetails}
          setModalIsOpen={setGeneralModalDetails}
        />
      )}
      {/*}
      <FreeTrial
        isOpen={trialModalIsOpen}
        onRequestClose={closeTrialModal}
        featureMsg={featureMsg}
      />
      <PurchaseSub isOpen={purchaseSubModalIsOpen} onRequestClose={closePurchaseSubModal} />
  */}

      <span className="logoSpaceHolder">&nbsp;</span>
      {user && user.level && scriptLevel ? (
        <span className="popoverParent">
          {type === 'pro' &&
          (scriptLevel.toLowerCase() === 'pro freemium' ||
            scriptLevel.toLowerCase() === 'pro freemium10' ||
            scriptLevel.toLowerCase() === 'basicplus') &&
          user.level.toLowerCase() !== 'pro' ? (
            <>
              <Icon
                icon="Camera"
                color="White"
                size={20}
                customClass="proImg"
                onClick={() => setModalIsOpen(true)}
                customStyle={{
                  paddingRight: '3px',
                }}
              />
              <span
                className="popover"
                style={{ width: '120px', ...(popoverStyle ? popoverStyle : {}) }}
              >
                This is a pro feature. You are using it for free.
              </span>
            </>
          ) : type === 'user' && user.level.toLowerCase() !== 'pro' ? (
            <>
              <Icon
                icon="Camera"
                color="Gold"
                size={20}
                customClass="proImg"
                onClick={() => setModalIsOpen(true)}
                customStyle={{
                  paddingRight: '3px',
                }}
              />
              <span
                className="popover"
                style={{ width: '120px', ...(popoverStyle ? popoverStyle : {}) }}
              >
                You do not have an active Pro subscription and/or credit card has expired. Please
                renew your subscription.
              </span>
            </>
          ) : type === 'pro' &&
            user.availableCredits &&
            user.availableCredits.totalCredits >= 100 &&
            scriptLevel.toLowerCase() !== 'pro' &&
            scriptLevel.toLowerCase() !== 'pro freemium' &&
            scriptLevel.toLowerCase() !== 'pro freemium10' &&
            user.level.toLowerCase() === 'pro' ? (
            <>
              <Icon
                icon="Camera"
                color="White"
                customClass="proImg"
                onClick={() => upgradeScriptModal(true)}
                customStyle={{
                  width: '20px',
                  paddingRight: '3px',
                }}
              />
              <span
                className="popover"
                style={{ width: '120px', ...(popoverStyle ? popoverStyle : {}) }}
              >
                Do you want to convert this script to Pro?
              </span>
            </>
          ) : type === 'pro' &&
            user.availableCredits &&
            user.availableCredits.totalCredits < 100 &&
            scriptLevel.toLowerCase() !== 'pro' &&
            user.level.toLowerCase() === 'pro' ? (
            <a href="https://www.shotkraft.com/contact-us">
              <Icon icon="Camera" color="Gold" customClass="proImg" />
              <span
                className="popover"
                style={{ width: '120px', ...(popoverStyle ? popoverStyle : {}) }}
              >
                You used up your Pro credits for this month. Try again next month or contact us here
                to buy an additional project for $6.50
              </span>
            </a>
          ) : type === 'pro' &&
            scriptLevel.toLowerCase() !== 'pro' &&
            user.level.toLowerCase() !== 'pro' ? (
            <>
              <Icon
                icon="Camera"
                color="Gold"
                customClass="proImg"
                onClick={() => setModalIsOpen(true)}
              />
              <span
                className="popover"
                style={{ width: '120px', ...(popoverStyle ? popoverStyle : {}) }}
              >
                {featureMsg
                  ? featureMsg
                  : 'You ran out of credits, please click here to upgrade to Pro.'}
              </span>
            </>
          ) : null}
        </span>
      ) : null}
    </>
  );
}

export default ProFlag;
