// Import Libraries
import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import Creatable, { useCreatable } from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

// Import Services
import dictionaryService from '../../services/dictionaryService';

// Construct Component
const CreateableMultiSelectDropdown = ({
  optionList,
  selected,
  baseItem,
  selectedFieldName,
  changeHandler,
  addOption,
  label,
  formatFields,
  id,
}) => {
  const [values, setValues] = useState([]);
  const [styleType, setStyleType] = useState('focus');
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const fieldId = id || 'creatableMultiSelectDropdown';
  const selectRef = useRef(null);

  const updateValues = (values) => {
    let selectedValues = [];
    if (values && values.length > 0) {
      selectedValues = values
        ? values
            .map((item) => optionList.find((options) => options.value === item.value))
            .filter(
              (item) => item && item.value !== null && item.value !== undefined && item.value > 0
            )
        : [];
    }
    setValues(selectedValues);
    return selectedValues;
  };

  const handleChange = async (updated) => {
    let update = updateValues(updated);
    await changeHandler(baseItem.id, selectedFieldName, update);
  };

  const handleAddItem = async (value) => {
    let newItem = await addOption(value, selectedFieldName);
    let itemList = values;
    itemList.push(newItem);
    handleChange(itemList);
  };

  const handleMouseClick = () => {
    if (selectRef.current) {
      selectRef.current.focus();
      setMenuIsOpen(true);
    }
  };

  const closeMenu = () => {
    //if (selectRef.current) {
    //selectRef.current.focus(false);
    setMenuIsOpen(false);
    //}
  };

  useEffect(() => {
    const processSelected = async () => {
      let formattedSelected = selected;
      if (formatFields && formatFields.value && formatFields.label) {
        formattedSelected = await dictionaryService.formatForSelect(
          selected,
          formatFields.value,
          formatFields.label
        );
      } else if (selected && selected.length > 0) {
        formattedSelected = optionList.filter((item) => selected.includes(item.value));
      }
      updateValues(formattedSelected);
    };
    processSelected();
  }, [optionList, selected]);

  useEffect(() => {
    if (values.length == 0) {
      setStyleType('static');
    } else {
      setStyleType('focus');
    }
  }, [values]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon className="dropdownCaret" icon={faCaretDown} />
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      className={`${styleType}LabelField`}
      id={fieldId}
      onClick={handleMouseClick}
      onContextMenu={handleMouseClick}
      onMouseLeave={closeMenu}
    >
      <span className={`${styleType}Label`}>
        <span className={`${styleType}LabelText`}>{`${label}`}</span>
      </span>
      <Creatable
        ref={selectRef}
        className="multiSelectDropdown withFormMargin"
        classNamePrefix="multiSelectDropdown"
        components={{ DropdownIndicator }}
        unstyled
        isMulti
        options={optionList}
        value={values}
        onChange={handleChange}
        isSearchable
        onCreateOption={(inputValue) => {
          handleAddItem(inputValue);
        }}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
    </div>
  );
};

export default CreateableMultiSelectDropdown;
