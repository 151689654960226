// Import Libraries
import React, { useState } from 'react';

// Import Components
import ScriptExport from '../../Modals/ScriptExport';
import Icon from '../../Elements/Icon';

// Construct Component
const ExportProjectButton = () => {
  const [scriptModalIsOpen, setScriptModalIsOpen] = useState(false);

  const exportScript = () => {
    setScriptModalIsOpen(true);
  };

  return (
    <>
      <button className="buttonGray" style={{ float: 'right' }} onClick={exportScript}>
        <Icon icon="Export" size={15} color="Black" />
        Export Shotlist
      </button>
      <ScriptExport modalIsOpen={scriptModalIsOpen} setModalIsOpen={setScriptModalIsOpen} />
    </>
  );
};

export default ExportProjectButton;
