// Import Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// Import Components
import Icon from '../Elements/Icon';

// Setup Modal to Root
Modal.setAppElement('#root');

const UpgradeMembership = ({ isOpen, onRequestClose }) => {
  return (
    <Modal isOpen={isOpen} contentLabel="Idle Modal" className="modal wide">
      <div className="close-button" role="button" onClick={() => onRequestClose()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className="modalHeader bold">
        <Icon
          icon="Camera"
          color="White"
          customClass="proImg"
          customStyle={{ marginRight: '20px', marginTop: '-5px' }}
        />
        Great News! You've been granted a 30 day free trial!
      </div>

      <div className="modalContent">
        <div className="FullWidthFlex Padded" style={{ paddingBottom: '30px' }}>
          <span className="OnePortionFlex Padded" style={{ borderRight: '1px solid #cccccc' }}>
            We're offering a 30 day free trial. What's the catch? There isn't one! We know you're
            going to love our product and can't wait to show you all that it has to offer.
            <br />
            <br />
            We will let you know when your trial is about to expire. For now, let's get shot
            listing!
          </span>
          <span className="OnePortionFlex Padded">
            <div className="label">You get more with Pro</div>
            <div>
              <ul>
                <li>More Fields</li>
                <li>Flexible Export Options</li>
                <li>Generate all shots in one click (coming soon)</li>
                <li>Upload Images to Shots (coming soon)</li>
              </ul>
            </div>
          </span>
        </div>

        <div className="FullWidthFlex Padded">
          <button
            className="OnePortionFlex buttonClone"
            style={{ marginRight: '70px' }}
            onClick={() => onRequestClose(false)}
          >
            Next
          </button>
          <span className="OnePortionFlex"></span>
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeMembership;
