// Import Libraries
import React, { useState, useContext, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import Components
import PdfViewer from '../../components/Fragments/PDFviewer';
import UpgradeMembership from '../../components/Modals/upgradeMembership.js';
import FreeTrial from '../../components/Modals/freeTrial.js';
import Stepper from '../../components/Elements/Stepper.js';
import ProcessScriptModal from './processingScriptModal.js';
import Icon from '../../components/Elements/Icon';

// Import Services
import UserService from '../../services/userService';
import ScriptService from '../../services/scriptService';

// Import User Context
import { UserContext } from '../../App';
import SessionContext from '../../context/Session';

// Construct Component
const UploadScriptBox = () => {
  const { user, setUser } = useContext(UserContext);
  const { isMobile } = useContext(SessionContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [validFile, setValidFile] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [scriptId, setScriptId] = useState('');
  const [scriptName, setScriptName] = useState('');
  const [scriptNameError, setScriptNameError] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [fileProcessing, setFileProcessing] = useState(null);
  const [credits, setCredits] = useState(100);
  const fileInputRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [processingScript, setProcessingScript] = useState(false);
  const [trialModalIsOpen, setTrialModalIsOpen] = useState(false);

  function closeMembershipModal(goToFreeTrial) {
    setModalIsOpen(false);
    if (goToFreeTrial) {
      setTrialModalIsOpen(true);
    }
  }

  function closeTrialModal() {
    setCredits(300);
    setTrialModalIsOpen(false);
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setValidFile(validateFile(file));
    handleScriptNameChange({ target: { value: scriptName } });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
    setValidFile(validateFile(file));
    setIsDragging(false);
  };

  const validateFile = (file) => {
    return file.type === 'application/pdf';
  };

  const handleUpload = async () => {
    try {
      setFileProcessing(true);
      let logId = await UserService.addActionLog('Upload File', null, null, scriptName);
      setUploadError(null);
      let userData = user;

      if (!userData) {
        console.error('No user data found. Fetching...');
        userData = await UserService.getUserData();
        setUser(userData);
      }

      if (!scriptName) {
        setUploadError('Error: Missing Script Title');
      } else if (!validFile) {
        setUploadError('Error: Invalid File');
      } else if (!userData.id) {
        setUploadError('Error: Invalid User');
      } else {
        let uploadResponse = await ScriptService.uploadScript(
          userData.id,
          scriptName,
          selectedFile
        );
        setTimeout(() => {
          setUploadError(
            'O-oh! Something went wrong. Check your internet, reload, and try again. '
          );
          return;
        }, 70000);
        if (uploadResponse.success) {
          UserService.updateActionLog(logId, uploadResponse.data.scriptId, 'script', '');
          setProcessingScript(true);
          ScriptService.generateSceneList(
            uploadResponse.data.scriptId,
            uploadResponse.data.filePath
          );
          setTimeout(() => {
            return;
          }, 5000);
          setSelectedFile(null);
          setValidFile(false);
          setIsDragging(false);
          setScriptId(uploadResponse.data.scriptId);
          UserService.completeActionLog(logId);
        } else {
          setUploadError('Error uploading file: ' + uploadResponse.data);
        }
      }
      setFileProcessing(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadError(
        'O-oh! This is one loooong script. Your file exceeds the max file size of 5M.'
      );
      setFileProcessing(false);
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setValidFile(false);
    setIsDragging(false);
    setScriptName('');
    setScriptId('');
    setUploadError(null);
  };

  const handleScriptNameChange = async (event) => {
    try {
      let tempScriptName = event.target.value;
      let uploadButton = document.getElementById('uploadButton');

      if (tempScriptName === '' || !tempScriptName || tempScriptName.length < 1) {
        if (uploadButton) {
          uploadButton.setAttribute('disabled', 'disabled');
        }
        setScriptNameError('Please enter a title for your project');
      } else {
        if (uploadButton) {
          uploadButton.removeAttribute('disabled');
        }
        setScriptNameError(null);
      }
      setScriptName(tempScriptName);
    } catch (error) {
      console.error('Error validating script title:', error);
    }
  };

  useEffect(() => {
    async function getUserCredits() {
      let credits = await UserService.getUserAvailableCredits();
      setCredits(credits);
    }
    getUserCredits();
  });

  return (
    <div>
      <UpgradeMembership
        isOpen={modalIsOpen}
        onRequestClose={closeMembershipModal}
        featureMsg="Continue shotlisting fast with Pro features"
      />
      <FreeTrial isOpen={trialModalIsOpen} onRequestClose={closeTrialModal} />
      <ProcessScriptModal
        modalIsOpen={processingScript}
        setModalIsOpen={setProcessingScript}
        scriptId={scriptId}
      />

      <div className="fullWidthContainer">
        <div className="fullWidthContainer large bold">Let's get started</div>
        <p className="subHeader">Upload your script to start shotlisting</p>

        <div className="fullWidthContainer">
          <Stepper numSteps={3} onStep="2" />
        </div>
        <div className={`fullWidthContainer ${isMobile ? '' : 'DeepPadded'}`}>
          <div className="fullWidthContainer subHeader">Script Title </div>
          <input
            type="text"
            value={scriptName}
            onChange={handleScriptNameChange}
            placeholder="i.e. Murder Mystery"
            className={`fullWidthContainer Input ${scriptNameError ? 'InputError' : ''}`}
          />

          <div className="fullWidthContainer">
            <p className="errorText">{scriptNameError}</p>
          </div>

          {!selectedFile || !validFile ? (
            <div
              className={`fullWidthContainer`}
              style={{
                cursor: 'pointer',
                borderRadius: '8px',
                border: '1px dashed #D9DBDD',
              }}
            >
              <input
                type="file"
                id="fileInput"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <div
                className={`fullWidthContainer lightGrayBackground loading-box centered ${
                  isDragging ? 'dragging' : ''
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                htmlFor="fileInput"
                onClick={() => fileInputRef.current.click()} // Trigger file input click
              >
                <div className="DeepPadded" style={{ width: '100%', textAlign: '-webkit-center' }}>
                  <Icon icon="Upload" size={30} color="Purple" customClass="centered" />
                </div>
                <div className="centered subHeader navyText" style={{ width: '100%' }}>
                  Upload script PDF
                </div>
                <div
                  className="centered lightNavyText"
                  style={{ width: '100%', paddingBottom: '20px' }}
                >
                  Drop file here or <u>Select File</u>
                </div>
              </div>
            </div>
          ) : null}

          {selectedFile && !validFile ? (
            <div>
              <p className="errorText">
                This file is not a PDF. Please try again.
                <button onClick={handleCancel} className="linkText">
                  <FontAwesomeIcon icon="xmark" />
                </button>
              </p>
              <br />
            </div>
          ) : null}

          {user.level && user.level.toLowerCase() === 'basicplus' ? (
            <div>
              <p className="errorText">
                You are running out of pro credits. You may not be able to complete shotlisting this
                script with the benefit of pro features.
                <span className="linkText" onClick={() => setModalIsOpen(true)}>
                  Upgrade now.
                </span>{' '}
              </p>
              <br />
            </div>
          ) : (!credits || credits < 100) && user.level && user.level.toLowerCase() === 'pro' ? (
            <div>
              <p className="errorText">
                You are out of pro credits for this month. You will not be able to complete
                shotlisting this script with the benefit of pro features.
                <a className="linkText" href="https://www.shotkraft.com/help" target="_blank">
                  Contact Us to get more credits.
                </a>{' '}
              </p>
              <br />
            </div>
          ) : null}

          {validFile ? (
            <div>
              <div className="fullWidthContainer">
                Selected File: {selectedFile.name}
                <button onClick={handleCancel} className="linkText" style={{ float: 'right' }}>
                  Reset
                </button>
              </div>

              <div className="fullWidthContainer">
                <PdfViewer uploadedFile={selectedFile} startPage="1" />
              </div>
            </div>
          ) : null}

          <div className="fullWidthContainer Padded">
            <p className="centered">
              Don't have a script?{' '}
              <a className="purpleText" target="_blank" href="/DownloadSample">
                Download a sample
              </a>
            </p>
          </div>

          <div className="fullWidthContainer">
            <p className="errorText">{uploadError}</p>
          </div>

          {fileProcessing ? (
            <div className="fullWidthContainer">
              <span className="ThreePortionFlex purpleText">
                Hold tight while we are validating your script
              </span>
              <span style={{ width: '100%' }} className={`OnePortionFlex`}>
                <img
                  src="/Spinner-2.9s-297px.gif"
                  className="loadingIcon icon OnePortionFlex"
                  style={{ float: 'right', height: '40px', width: '40px' }}
                  alt="Loading.."
                />
              </span>
            </div>
          ) : (
            <div className="fullWidthContainer">
              <span className="OnePortionFlex"></span>
              <button
                className="ThreePortionFlex buttonClone MinSmall"
                onClick={handleUpload}
                disabled={!validFile || scriptNameError || fileProcessing}
              >
                Let's Go!
              </button>
              <span className="OnePortionFlex"></span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadScriptBox;
