// Import Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Import Components
import SceneTable from './sceneTable';
import ShotTable from './shotTable';
import ShotTableHeader from '../../components/Fragments/ShotTableHeader';

// Import Services
import UserService from '../../services/userService';
import ScriptService from '../../services/scriptService';
import Utils from '../../services/utilities';
import ScriptWebSocket from '../../services/scriptWebSocket';

// Context
import { UserContext } from '../../App';
import ScriptContext from '../../context/Script';
import SessionContext from '../../context/Session';

// Construct Component
const ShotList = ({ scriptId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { script, setScript } = useContext(ScriptContext);
  const { isMobile } = useContext(SessionContext);
  const [sceneClass, setSceneClass] = useState('slide-out-left');
  const [shotClass, setShotClass] = useState('slide-out-right');
  const [error, setError] = useState(false);
  const [view, setView] = useState({
    pdfActive: true,
    viewColumns: 2,
    isLoading: true,
    sceneIndex: 0,
    expandShots: false,
    uploadedFile: null,
    manualEntry: false,
    updateDictionary: false,
    includeImages: false,
    sceneImagesAllowed: false,
    onScreen: 'scene',
  });

  useEffect(() => {
    if (location.state && location.state.uploadedFile) {
      setView({ ...view, uploadedFile: location.state.uploadedFile });
    }
  }, [location.state]);

  useEffect(() => {
    if (isMobile) {
      if (view.onScreen === 'scene') {
        setSceneClass('offscreen-left');
        setShotClass('slide-out-right');
        setTimeout(() => {
          setSceneClass('slide-in');
        }, 100);
        setTimeout(() => {
          setShotClass('hidden');
        }, 500);
      } else if (view.onScreen === 'shot') {
        setShotClass('offscreen-right');
        setSceneClass('slide-out-left');
        setTimeout(() => {
          setShotClass('slide-in');
        }, 100);
        setTimeout(() => {
          setSceneClass('hidden');
        }, 500);
      }
    }
  }, [view.onScreen]);

  const setupPageView = async (tempScript) => {
    let manualEntry = false;
    let pdfPath = null;
    if (tempScript.script_path) {
      pdfPath = await ScriptService.getPdf(tempScript.script_path);
    }
    if (!pdfPath) {
      manualEntry = true;
    }
    let status = 0;
    if (script?.scenes[0]?.ai_status > 0) {
      status = script.scenes[0].ai_status;
    }
    setView((prevView) => ({
      ...prevView,
      uploadedFile: pdfPath || prevView.uploadedFile,
      manualEntry: manualEntry,
      selectedIndex: 0,
      viewColumns: window.innerWidth < 768 ? 1 : 2,
      isLoading: false,
      sceneImagesAllowed:
        tempScript?.features?.toLowerCase() === 'basic' && status === 0 ? false : true,
    }));
  };

  const setupPage = async () => {
    let pageViewSet = false;
    if (scriptId && user) {
      try {
        let tempScript = await ScriptService.getScriptById(scriptId);
        for (let i = 0; i < tempScript.scenes.length; i++) {
          tempScript.scenes[i].shot_list = Utils.buildTempShot(tempScript, tempScript.scenes[i]);
        }
        setScript((currentScript) => ({
          ...currentScript,
          ...tempScript,
        }));
        if (
          tempScript.user_id !== user.id &&
          window.location.pathname.substring(0, 6) !== '/admin'
        ) {
          navigate(-1);
          return;
        }
        if (tempScript.page_count !== tempScript.last_processed_page) {
          let counter = 0;
          while (counter < 20 && tempScript.page_count !== tempScript.last_processed_page) {
            if (pageViewSet === false && tempScript.scenes.length > 0) {
              pageViewSet = true;
              setupPageView(tempScript);
            }
            await new Promise((resolve) => setTimeout(resolve, 4000));
            tempScript = await ScriptService.getScriptById(scriptId);
            counter++;
            for (let i = 0; i < tempScript.scenes.length; i++) {
              tempScript.scenes[i].shot_list = Utils.buildTempShot(
                tempScript,
                tempScript.scenes[i]
              );
            }
            setScript((currentScript) => ({
              ...currentScript,
              ...tempScript,
            }));
          }
        } else {
          setupPageView(tempScript);
        }

        if (tempScript.scenes.length === 0) {
          setError(true);
        }
      } catch (err) {
        console.error('Error setting up page:', err);
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (scriptId && user && user.id) {
      UserService.addActionLog('View Script Shot List', scriptId, 'script');
      setupPage();
    }
  }, [scriptId, user]);

  useEffect(() => {
    if (
      script &&
      script.scenes &&
      script.scenes.length > 0 &&
      script.scenes[0].id &&
      view.isLoading
    ) {
      setView((prevView) => ({
        ...prevView,
        isLoading: true,
      }));
    }
  }, [script.scenes]);

  return (
    <div>
      {script && script.id ? (
        <ScriptWebSocket scriptId={scriptId} user={user} script={script} setScript={setScript} />
      ) : null}

      {!view.isLoading && !error && (
        <div>
          <ShotTableHeader view={view} setView={setView} />
        </div>
      )}

      <div>
        {view.isLoading && !error && (
          <div className="loadingMessage" style={{ padding: '30px' }}>
            Bear with us while we organize your scenes. This could take a minute or two. We promise
            it is worth the wait!
            <br />
            <img
              src="/Spinner-2.9s-297px.gif"
              style={{ height: '200px', width: '200px' }}
              className="smallLoadingGif"
              alt="Loading.."
            />
          </div>
        )}
        {error && (
          <div className="mainBox sizeUp errorMessage">
            <h3>Well then. This is embarrassing. We don’t know what happened here.</h3>
            <br />
            Try hitting refresh on your browser, if that does not work, it is likely that we are
            unable to find scenes within the file you uploaded. Please try again and ensure the
            script meets the following critieria:
            <ul>
              <li>
                Please ensure your script has slug lines for each scene such as "INT. LOCATION -
                NIGHT"
              </li>
              <li>
                Scripts do not necessarily need to be in English each scene must begin with INT,
                EXT, OMITTED, or SCENE.{' '}
              </li>
            </ul>
            <br />
            <br />
            If your script meets this criteria, please{' '}
            <a href="https://www.shotkraft.com/help" target="_blank">
              contact us
            </a>{' '}
            in this form so we can assist you. We are sorry for the inconvenience.
          </div>
        )}
        {!view.isLoading && !error && (
          <div className="FullWidthFlex">
            <div className={`${view.viewColumns == 2 ? 'OneHalfWidth' : 'FullWidth'} `}>
              {!isMobile ? (
                <SceneTable view={view} setView={setView} />
              ) : (
                <>
                  <div className={`table-container ${sceneClass}`}>
                    <SceneTable view={view} setView={setView} />
                  </div>
                  <div className={`table-container ${shotClass}`}>
                    <ShotTable view={view} setView={setView} />
                  </div>
                </>
              )}
            </div>

            {view.viewColumns == 2 && !isMobile && (
              <div className="OneHalfWidth">
                <ShotTable view={view} setView={setView} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShotList;
