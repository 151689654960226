// Import Libraries
import React, { useState, useContext } from 'react';
import Modal from 'react-modal';

// Import Content
//import privacyPolicy from '../content/privacyPolicy.html';

// Import Context
import SessionContext from '../context/Session';

Modal.setAppElement('#root');

// Construct Component
function Footer() {
  const { generalModalDetails, setGeneralModalDetails } = useContext(SessionContext);

  const openPrivacyPolicy = async () => {
    let policy = await fetch('../../privacyPolicy.html');
    if (policy.ok) {
      let policyText = (await policy.text()).toString();
      setGeneralModalDetails({
        open: true,
        header: '',
        message: <div dangerouslySetInnerHTML={{ __html: policyText }} />,
      });
    }
  };

  const openTerms = async () => {
    let terms = await fetch('../../termsofUse.html');
    if (terms.ok) {
      let termsText = (await terms.text()).toString();
      setGeneralModalDetails({
        open: true,
        header: '',
        message: <div dangerouslySetInnerHTML={{ __html: termsText }} />,
      });
    }
  };
  return (
    <footer className="footer">
      <div className="fullWidthContainer">
        <span className="OnePortionFlex">
          <div>
            <a
              style={{ color: 'white', textDecoration: 'none' }}
              href="https://www.shotkraft.com/faq"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQ
            </a>
            <div></div>
            <a
              style={{ color: 'white', textDecoration: 'none' }}
              href="https://www.shotkraft.com/help"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </div>
        </span>
        <span className="OnePortionFlex centered">&copy; 2024</span>
        <span className="OnePortionFlex centered">
          <div style={{ cursor: 'pointer' }} onClick={() => openPrivacyPolicy()}>
            Privacy Policy
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => openTerms()}>
            Terms of Use
          </div>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
