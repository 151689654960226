// Import Libraries
import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

// Construct Component
const SelectBox = ({
  selectLabel,
  selectOptions,
  selectedValue,
  handleSelectChange,
  labelStyle,
  useClass,
  preMapped,
  placeholder,
}) => {
  const [options, setOptionValue] = useState([]);
  const [value, setValue] = useState(null);
  const [styleType, setStyleType] = useState('focus');

  useEffect(() => {
    let style = labelStyle || 'focus';
    setStyleType(style);
  }, [labelStyle]);

  useEffect(() => {
    if (!preMapped) {
      let mapped_options = selectOptions.map((item) => ({ label: item, value: item }));
      setOptionValue(mapped_options);

      if (selectedValue) {
        setValue({ label: selectedValue, value: selectedValue });
      } else {
        setValue(null);
      }
    } else {
      setOptionValue(selectOptions);
      if (selectedValue) {
        setValue(selectedValue);
      } else {
        setValue(null);
      }
    }
  }, [selectOptions, selectedValue, preMapped]);

  const handleChange = async (selectedOption) => {
    handleSelectChange(selectedOption.value);
    setValue({ label: selectedOption.value, value: selectedOption.value });
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon className="dropdownCaret" icon={faCaretDown} />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className={`${styleType}LabelField withFormMargin ${useClass}`}>
      <span className={`${styleType}Label`}>
        <span className={`${styleType}LabelText`}>{`${selectLabel}`}</span>
      </span>
      <Select
        key={`${selectLabel}`}
        className="multiSelectDropdown"
        classNamePrefix="multiSelectDropdown"
        style={{ textAlign: 'left' }}
        components={{ DropdownIndicator }}
        unstyled
        value={value}
        options={options}
        onChange={handleChange}
        placeholder={placeholder || 'Select one'}
      />
    </div>
  );
};

export default SelectBox;
